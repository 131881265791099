import * as React from "react"
import PropTypes from "prop-types"
import { useStaticQuery, graphql } from "gatsby"

import MyNavbar from "./MyNavbar"
import seo from "./seo"
import Footer from "./Footer"
import "./layout.css"
import "./layout.scss"

import header_background from "../images/header_background.webp"

const Layout = ({ children }) => {
  const data = useStaticQuery(graphql`
    query SiteTitleQuery {
      site {
        siteMetadata {
          title,
          description
        }
      }
    }
  `)

  return (
<>
<div className="my-5">
<header className="container">
    <img src={header_background}
    alt="Pardeeville Carshow September 4th, 2023."
    className="header-image"
    />
  </header>
      
      <div className="container" >
      <div id="main-wrapper" >
      <MyNavbar siteTitle={data.site.siteMetadata?.title || `Title`} />
         <hr className="me-3 mt-0 mb-0" />
        <main>{children}</main>
      </div>
    </div>
    <Footer />
</div>
  </>
  )
}

Layout.propTypes = {
  children: PropTypes.node.isRequired,
}

export default Layout
