import React from "react"
import { Link } from "gatsby"

import "./myNavbar.css"

const MyNavbar = () => (
  <nav className="navbar navbar-expand-lg  ">
  <div className="container-fluid">
    <button className="navbar-toggler bg-light" type="button" data-bs-toggle="collapse" data-bs-target="#navbarNav" aria-controls="navbarNav" aria-expanded="false" aria-label="Toggle navigation">
      <span className="navbar-toggler-icon"></span>
    </button>
    <div className="collapse navbar-collapse" id="navbarNav">
      <ul className="navbar-nav">
        <li className="nav-item">
          <Link to="/" className="nav-link">
            Home
          </Link>
        </li>
            <li className="nav-item">
          <Link to="/our-mission" className="nav-link">
            Our Mission
          </Link>
        </li>
          <li className="nav-item">
          <Link to="/carshow-general-info" className="nav-link">
            Car Show Info
          </Link>
        </li>
      
         <li className="nav-item">
          <Link to="/trophy-list-and-classes" className="nav-link">
            Trophy Classes & Rules
          </Link>
        </li>
        <li className="nav-item">
          <Link to="/tractor-show" className="nav-link">
            Tractor Show
          </Link>
        </li>
     
      </ul>
   
    </div>
    
  </div>
     
</nav>
)

export default MyNavbar
