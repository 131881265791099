import React from "react"




import "./footer.css"
import "../fontawesome/css/all.min.css"
 import  "../images/footer_background.png"


const Footer = () => (
  <footer   >
        <div className="container">

          <div className="row">
<div className="text-center"><p>© Pardeeville Car Show - 2024</p></div>
        </div>
    </div>
  </footer>
)



export default Footer
